@import url(https://fonts.googleapis.com/css2?family=Hina+Mincho&family=Kiwi+Maru:wght@300&family=Sawarabi+Mincho&family=Shippori+Mincho&family=Zen+Kaku+Gothic+New:wght@300&family=Zen+Maru+Gothic:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hina+Mincho&family=Kiwi+Maru:wght@300&family=Sawarabi+Mincho&family=Shippori+Mincho&family=Zen+Kaku+Gothic+New:wght@300&family=Zen+Maru+Gothic:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  width: 100vw;
}

a {
  text-decoration: none;
}

.amplify-tabs {
  display: none !important;
}
.header-container>li {
    width: 100%;
    list-style: none;
}

.header-left {
    display: flex;
    width: 100%;
}

.header-right {
    width: 50%;
}

.company-name {
    font-size: 30px;
    color: rgb(0, 162, 255);
    font-family: 'Sawarabi Mincho', sans-serif;
    margin-left: 10%;
    transition: 0.3s;
}

.company-name:hover {
    color: rgb(199, 232, 252);
    transition: 0.3s;
}

.connect {
    font-size: 15px;
    color: #FFFF;
}

@media screen and (max-width: 768px) {
    .company-name {
        font-size: 8px;
        color: rgb(0, 162, 255);
        font-family: 'Sawarabi Mincho', sans-serif;
        margin-left: 10%;
    }

    .connect {
        font-size: 6px;
        color: #FFFF;
    }
}

.logo:hover {
    opacity: 0.7;
}

.header-wrapper {
    position: -webkit-sticky;
    position: sticky;
    height: 10vh;
    width: 100vw;
    top: 0;
    background-color: rgba(0, 0, 0, 0.788);
    z-index: 9999;
    box-shadow: 0px 29px 23px -25px rgba(0, 0, 0, 0.6);
}

.header-container {
    display: flex;
    width: 100vw;
    height: 100%;
}

.header-item {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.login-btn {
    position: absolute;
    right: 5%;
    padding: 10px;
    background-color: rgb(248, 100, 2);
    border-radius: 5px;
    font-size: 16px;
    color: rgb(255, 255, 255);
    transition: 0.3s;
}

.login-btn:hover {
    background-color: rgb(248, 191, 2);
    transition: 0.3s;
}

@media screen and (max-width: 768px) {
    .login-btn {
        display: none;
    }
}
.footer-wrapper {
    position: -webkit-sticky;
    position: sticky;
    background-color: rgb(1, 16, 34);
}

.footer-collection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: ;
}

.footer-item {
    display: flex;
    width: 100%;
    height: 300px;
    margin: auto;
    margin-bottom: 50px;
}

.footer-content {
    padding-top: 5%;
    color: #FFFF;
    font-size: 15px;
    display: block;
    list-style: none;
}

.footer-content >ul {
    list-style: none;
}

.connect {
    padding: 5%;
    width: 100%;
}

.connect-icon {
    padding-top: 10%;
    width: 10%
}

.tell-icon {
    width: 100%;
}

.copyright {
    color: #FFFF;
    text-align: center;
    font-size: 15px;
    padding-bottom: 50px;
}
.list-style-dash > li {
    list-style: '- '
}
.topPage-wrapper {
    margin: auto;
}

.top-image-wrapper {
    position: relative;
}

.top-image-title {
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    text-align: center;
    width: 100%;
    font-size: 60px;
    font-family: 'Sawarabi Mincho', sans-serif;
    color: rgba(255, 255, 255, 1);
    font-weight: lighter;
    margin: auto;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    position: absolute;
}

.top-image-text {
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    text-align: center;
    width: 100%;
    font-size: 30px;
    font-family: 'Sawarabi Mincho', sans-serif;
    color: rgba(255, 255, 255, 1);
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    position: absolute;
}

.top-image {
    width: 100vw;
}

@media screen and (max-width: 768px) {
    .top-image {
        margin-top: -2vh;
        width: 100vw;
    }
}

.content-title>p {
    color: #004696;
    width: 100%;
    font-family: 'Shippori Mincho', serif;
    font-size: 40px;
    text-align: center;
    line-height: 1.4em;
}

@media screen and (max-width:768px) {
    .content-title>h2 {
        width: 85%;
        padding: 10px;
        margin-left: 2%;
        border-left: 4px solid #000;
        border-bottom: 2px solid #000;
    }
}

.recommendation-image {
    width: 100%;
    margin: 10px;
}

.recommendation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: ;
    width: 70%;
    margin: auto;
}

.business-content-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows:Ï;
    width: 70%;
    margin: auto;
    
}

@media screen and (max-width: 768px) {

    .description-wrapper,
    .recommendation {
        display: block;
    }
}

.business-image {
    margin: 10px;
    width: 100%;
    background-color: rgb(149, 169, 207);
    transform: scale(1);
    transition: 0.3s;
}

.business-image:hover {
    background-color: rgba(149, 169, 207,0.7);
    transform: scale(1.1);
    transition: 0.3s;
}

.job-description,
.business-content {
    overflow: hidden;
    position: relative;
    margin: auto;
    margin-bottom: 30px;
    width: 100%;
}

.job-title {
    font-size: 35px;
}

@media screen and (max-width: 768px) {
    .job-description {
        width: 100%;
        position: static;
        position: initial;
    }
}

.layer-text {
    padding: 5%;
    opacity: 1;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
}

.layer-text>p {
    font-size: 20px;
    color: rgb(24, 23, 23)
}

.business-layer-text {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.685);
    width: 100%;
    height: 30%;
    padding: 5%;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0%;
}

.business-layer-text>p {
    text-align: center;
    font-size: 20px;
}

@media screen and (max-width: 768px) {
    .layer-text {
        opacity: 1;
        width: 100%;
        height: 100%;
        color: rgb(7, 7, 7);
        background: rgba(255, 255, 255, 1);
        position: static;
        position: initial;
        top: 0;
        pointer-events: none;
    }
}

/*
.layer-text:hover {
    opacity: 1.0;
    color: snow;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 17, 0.7);
    position: absolute;
    transition: 0.5s;
}
*/


@media screen and (max-width: 768px) {
    .layer-text {
        content: "";
        width: 100%;
        height: 100%;
        position: static;
        position: initial;
    }
}

.infomation {
    width: 100%;
}

.info-image {
    padding: 5%;
    width: 90%;
}
