@import url('https://fonts.googleapis.com/css2?family=Hina+Mincho&family=Kiwi+Maru:wght@300&family=Sawarabi+Mincho&family=Shippori+Mincho&family=Zen+Kaku+Gothic+New:wght@300&family=Zen+Maru+Gothic:wght@300&display=swap');

.header-container>li {
    width: 100%;
    list-style: none;
}

.header-left {
    display: flex;
    width: 100%;
}

.header-right {
    width: 50%;
}

.company-name {
    font-size: 30px;
    color: rgb(0, 162, 255);
    font-family: 'Sawarabi Mincho', sans-serif;
    margin-left: 10%;
    transition: 0.3s;
}

.company-name:hover {
    color: rgb(199, 232, 252);
    transition: 0.3s;
}

.connect {
    font-size: 15px;
    color: #FFFF;
}

@media screen and (max-width: 768px) {
    .company-name {
        font-size: 8px;
        color: rgb(0, 162, 255);
        font-family: 'Sawarabi Mincho', sans-serif;
        margin-left: 10%;
    }

    .connect {
        font-size: 6px;
        color: #FFFF;
    }
}

.logo:hover {
    opacity: 0.7;
}

.header-wrapper {
    position: sticky;
    height: 10vh;
    width: 100vw;
    top: 0;
    background-color: rgba(0, 0, 0, 0.788);
    z-index: 9999;
    box-shadow: 0px 29px 23px -25px rgba(0, 0, 0, 0.6);
}

.header-container {
    display: flex;
    width: 100vw;
    height: 100%;
}

.header-item {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.login-btn {
    position: absolute;
    right: 5%;
    padding: 10px;
    background-color: rgb(248, 100, 2);
    border-radius: 5px;
    font-size: 16px;
    color: rgb(255, 255, 255);
    transition: 0.3s;
}

.login-btn:hover {
    background-color: rgb(248, 191, 2);
    transition: 0.3s;
}

@media screen and (max-width: 768px) {
    .login-btn {
        display: none;
    }
}