.footer-wrapper {
    position: sticky;
    background-color: rgb(1, 16, 34);
}

.footer-collection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: ;
}

.footer-item {
    display: flex;
    width: 100%;
    height: 300px;
    margin: auto;
    margin-bottom: 50px;
}

.footer-content {
    padding-top: 5%;
    color: #FFFF;
    font-size: 15px;
    display: block;
    list-style: none;
}

.footer-content >ul {
    list-style: none;
}

.connect {
    padding: 5%;
    width: 100%;
}

.connect-icon {
    padding-top: 10%;
    width: 10%
}

.tell-icon {
    width: 100%;
}

.copyright {
    color: #FFFF;
    text-align: center;
    font-size: 15px;
    padding-bottom: 50px;
}
.list-style-dash > li {
    list-style: '- '
}