@import url('https://fonts.googleapis.com/css2?family=Hina+Mincho&family=Kiwi+Maru:wght@300&family=Sawarabi+Mincho&family=Shippori+Mincho&family=Zen+Kaku+Gothic+New:wght@300&family=Zen+Maru+Gothic:wght@300&display=swap');

.topPage-wrapper {
    margin: auto;
}

.top-image-wrapper {
    position: relative;
}

.top-image-title {
    backdrop-filter: blur(5px);
    text-align: center;
    width: 100%;
    font-size: 60px;
    font-family: 'Sawarabi Mincho', sans-serif;
    color: rgba(255, 255, 255, 1);
    font-weight: lighter;
    margin: auto;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    position: absolute;
}

.top-image-text {
    backdrop-filter: blur(5px);
    text-align: center;
    width: 100%;
    font-size: 30px;
    font-family: 'Sawarabi Mincho', sans-serif;
    color: rgba(255, 255, 255, 1);
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    position: absolute;
}

.top-image {
    width: 100vw;
}

@media screen and (max-width: 768px) {
    .top-image {
        margin-top: -2vh;
        width: 100vw;
    }
}

.content-title>p {
    color: #004696;
    width: 100%;
    font-family: 'Shippori Mincho', serif;
    font-size: 40px;
    text-align: center;
    line-height: 1.4em;
}

@media screen and (max-width:768px) {
    .content-title>h2 {
        width: 85%;
        padding: 10px;
        margin-left: 2%;
        border-left: 4px solid #000;
        border-bottom: 2px solid #000;
    }
}

.recommendation-image {
    width: 100%;
    margin: 10px;
}

.recommendation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: ;
    width: 70%;
    margin: auto;
}

.business-content-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows:Ï;
    width: 70%;
    margin: auto;
    
}

@media screen and (max-width: 768px) {

    .description-wrapper,
    .recommendation {
        display: block;
    }
}

.business-image {
    margin: 10px;
    width: 100%;
    background-color: rgb(149, 169, 207);
    transform: scale(1);
    transition: 0.3s;
}

.business-image:hover {
    background-color: rgba(149, 169, 207,0.7);
    transform: scale(1.1);
    transition: 0.3s;
}

.job-description,
.business-content {
    overflow: hidden;
    position: relative;
    margin: auto;
    margin-bottom: 30px;
    width: 100%;
}

.job-title {
    font-size: 35px;
}

@media screen and (max-width: 768px) {
    .job-description {
        width: 100%;
        position: initial;
    }
}

.layer-text {
    padding: 5%;
    opacity: 1;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
}

.layer-text>p {
    font-size: 20px;
    color: rgb(24, 23, 23)
}

.business-layer-text {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.685);
    width: 100%;
    height: 30%;
    padding: 5%;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0%;
}

.business-layer-text>p {
    text-align: center;
    font-size: 20px;
}

@media screen and (max-width: 768px) {
    .layer-text {
        opacity: 1;
        width: 100%;
        height: 100%;
        color: rgb(7, 7, 7);
        background: rgba(255, 255, 255, 1);
        position: initial;
        top: 0;
        pointer-events: none;
    }
}

/*
.layer-text:hover {
    opacity: 1.0;
    color: snow;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 17, 0.7);
    position: absolute;
    transition: 0.5s;
}
*/


@media screen and (max-width: 768px) {
    .layer-text {
        content: "";
        width: 100%;
        height: 100%;
        position: initial;
    }
}

.infomation {
    width: 100%;
}

.info-image {
    padding: 5%;
    width: 90%;
}