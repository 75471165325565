body {
  width: 100vw;
}

a {
  text-decoration: none;
}

.amplify-tabs {
  display: none !important;
}